import React, { HTMLAttributes, PropsWithChildren } from 'react';
import cx from 'classnames';

import stylesheet from './SwitcherLayout.less';

export type SwitcherLayoutProps = PropsWithChildren<{
  as?: React.ElementType;
  gap?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  minContainerWidth?: string;
}> & HTMLAttributes<HTMLElement>;

/**
 * `SwitcherLayout` puts all its children into equal columns on the same row. If
 * its container width falls under `minContainerWidth`, then `SwitcherLayout`
 * switches to a single-column layout.
 *
 * The columns don't have to be equal in width. You can define proportions by
 * setting `style={{ flexGrow: [number] }}` on the relevant columns.
 *
 * Based on the SwitcherLayout in every-layout.dev. If you don't have access to
 * every-layout.dev, the Flexbox Holy Albatross explains the concepts for free:
 * https://www.heydonworks.com/article/the-flexbox-holy-albatross-reincarnated
 */
const SwitcherLayout = React.forwardRef<HTMLDivElement, SwitcherLayoutProps>(
  function SwitcherLayout(
    {
      as: Component = 'div',
      children,
      className,
      gap = 2,
      minContainerWidth,
      ...props
    },
    forwardedRef,
  ) {
    // The reason `SwitcherLayout` is two elements (and the reason we do not
    // style `.switcher`) is to insulate the negative margin. Doing that enables
    // us to nest `SwitcherLayout`s. If we get rid of the wrapping `div`, then
    // the `SwitcherLayout` margins will clash with each other. This is the same
    // reason that `--measure` is set on `Component` and not the wrapping div.
    return (
      <div
        className={cx(stylesheet.switcher, className)}
        ref={forwardedRef}
        {...props}
      >
        <Component
          className={cx(gap && stylesheet[`spacing${gap}`])}
          style={{ '--measure': minContainerWidth }}
        >
          {children}
        </Component>
      </div>
    );
  },
);

export default SwitcherLayout;
