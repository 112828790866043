import React, { PropsWithChildren, HTMLAttributes } from 'react';
import cx from 'classnames';
import stylesheet from './ContentArea.less';

export type ContentAreaProps = PropsWithChildren<{
  as?: React.ElementType;
  size?: 'standard' | 'narrow' | 'wide';
}> & HTMLAttributes<HTMLElement>;

/**
 * Listbox is a primitive component used to power other design system components.
 * It should *NEVER* be used directly in app code.
 *
 * It's job is to abstract the fluid padding specs that define main content spaces in the app.
 */
const ContentArea = React.forwardRef<HTMLElement, ContentAreaProps>(
  function ContentArea(
    {
      as: Component = 'div',
      children,
      className,
      size = 'standard'
    },
    forwardedRef,
  ) {
    return (
      <Component
        className={cx(stylesheet.root, stylesheet[size], className)}
        ref={forwardedRef}
      >
        {children}
      </Component>
    );
});

export default ContentArea;
