import React from 'react';
import PropTypes from 'prop-types';
import Slider from './Slider';

const ChoiceSlider = React.forwardRef(function ChoiceSlider(
  { onChange, value, children, ...props },
  ref,
) {
  // const [numericValue, setNumericValue] = useState();
  const values = [];
  const cloned = [];
  let selectedIndex = 0;
  React.Children.forEach(children, (child, index) => {
    // retain the Tick's non-standard value prop then replace it with the array index so its compatible with Slider
    values.push(child.props.value);
    cloned.push(
      React.cloneElement(child, {
        value: index,
        key: child.props.value,
        choice: child.props.value,
      }),
    );

    if (value === child.props.value) {
      selectedIndex = index;
    }
  });

  return (
    <Slider
      onChange={(n) => onChange(values[n])}
      value={selectedIndex}
      ref={ref}
      {...props}
      aria-valuetext={value}
      max={values.length - 1}
      min={0}
      step={1}
      renderOutput={() => ''}
    >
      {cloned}
    </Slider>
  );
});

ChoiceSlider.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};

export default ChoiceSlider;
