import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';
import Button from '../Button';
import ActionList from '../ActionList';
import stylesheet from './ComboButton.less';

const ComboButton = React.forwardRef(function ComboButton(
  {
    className,
    children,
    disabled = false,
    isLoading = false,
    label,
    onClick,
    onActionListOpen,
    type = 'primary',
    size = 'large',
    purpose = 'standard',
    ...rest
  },
  forwardedRef,
) {
  const actionListLabelText = useDsTranslateMessage({
    id: 'mcds.combo_button.action_list_label_text',
    defaultMessage: 'Actions',
  });
  return (
    <div
      className={cx(stylesheet.root, className, {
        [stylesheet.secondary]: type === 'secondary',
        [stylesheet[size]]: size !== 'large',
      })}
    >
      <Button
        {...rest}
        type={type}
        ref={forwardedRef}
        className={cx(stylesheet.button, {
          [stylesheet[purpose]]: purpose !== 'standard',
          [stylesheet.secondary]: type === 'secondary',
        })}
        isLoading={isLoading}
        disabled={disabled || isLoading}
        onClick={onClick}
      >
        {label}
      </Button>
      <ActionList
        className={cx(stylesheet.actionList, {
          [stylesheet[purpose]]: purpose !== 'standard',
          [stylesheet.secondary]: type === 'secondary',
        })}
        disabled={isLoading || disabled}
        type={type}
        label={
          <React.Fragment>
            &nbsp;
            <span className="wink-visually-hidden">{actionListLabelText}</span>
          </React.Fragment>
        }
        onOpen={onActionListOpen}
        placement="bottom-end"
        data-testid="combobutton-expand-button"
      >
        {children}
      </ActionList>
    </div>
  );
});

ComboButton.propTypes = {
  /** This should only contain ActionListItems */
  children: PropTypes.node.isRequired,
  /** Makes the button unclickable */
  disabled: PropTypes.bool,
  /** When true, the button is disabled and also shows a loading indicator */
  isLoading: PropTypes.bool,
  /** The label for the button */
  label: PropTypes.node.isRequired,
  /** Click event for opening dropdown */
  onActionListOpen: PropTypes.func,
  /** Mouse click event */
  onClick: PropTypes.func,
  /** Standard is for the most common use cases. Passive is mostly used for binary actions or a list of equally weighted primaries. */
  purpose: PropTypes.oneOf(['standard', 'passive']),
  /** Size of ComboButton */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** A subset of button types, combo buttons cannot be tertiary */
  type: PropTypes.oneOf(['primary', 'secondary']),
};

export default ComboButton;
