import React, { AllHTMLAttributes, PropsWithChildren } from 'react';
import cx from 'classnames';
import ButtonOrLink from '../ButtonOrLink';
import stylesheet from './Button.less';

export type ButtonProps = PropsWithChildren<{
  /**
   * Only applies to Buttons without `href`. While most HTML attributes of
   * button are passed along. The "type" property is used to describe the
   * button's variant type (see below). To accomodate this, we rename the 'type'
   * attribute to 'htmlType'.
   */
  htmlType?: 'submit' | 'reset' | 'button';
  /** When true, the button is disabled and also shows a loading indicator */
  isLoading?: boolean;
  /** Standard is for the most common use cases. Passive is mostly used for binary actions or a list of equally weighted primaries. */
  purpose?: 'standard' | 'passive';
  /** Size of the button */
  size?: 'small' | 'medium' | 'large';
  /** The different variants of a Button */
  type: 'primary' | 'secondary' | 'tertiary';
}> &
  AllHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>;

const Button = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonProps
>(function Button(
  {
    children,
    className,
    disabled = false,
    htmlType = 'button',
    isLoading = false,
    size = 'large',
    purpose = 'standard',
    type,
    ...rest
  },
  forwardedRef,
) {
  return (
    <ButtonOrLink
      disabled={disabled || isLoading}
      className={cx(
        stylesheet.container,
        stylesheet[type],
        { [stylesheet[size]]: size !== 'large' },
        { [stylesheet[purpose]]: purpose !== 'standard' },
        className,
      )}
      type={htmlType}
      ref={forwardedRef}
      {...rest}
    >
      {isLoading && <span className={stylesheet.loading} />}
      {/* [FP-1214]: Temporary fix. Wrap with <span>s to get around a Google Translate issue. */}
      <span className={stylesheet.temporarySpan}>{children}</span>
    </ButtonOrLink>
  );
});

export default Button;
