import React from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';
import SwitcherLayout from '../SwitcherLayout';
import ContentArea from '../ContentArea';

import stylesheet from './PullQuote.less';

const PullQuote = React.forwardRef(function PullQuote(
  { eyebrow, quote, secondary, media, callToAction },
  forwardedRef,
) {
  return (
    <ContentArea className={stylesheet.root} ref={forwardedRef}>
      <SwitcherLayout>
        <div
          style={{
            flexGrow: 3,
          }}
        >
          <Text appearance="small-bold" className={stylesheet.eyebrow}>
            {eyebrow}
          </Text>
          <figure className={stylesheet.quoteContainer}>
            <blockquote>
              <Text appearance="heading-2">“{quote}”</Text>
            </blockquote>

            <figcaption className={stylesheet.secondaryContainer}>
              <Text appearance="small-secondary">{secondary}</Text>
            </figcaption>
          </figure>
          <div className={stylesheet.callToAction}>{callToAction}</div>
        </div>
        {media && <div className={stylesheet.mediaContainer}>{media}</div>}
      </SwitcherLayout>
    </ContentArea>
  );
});

PullQuote.propTypes = {
  /** Will contain a call to action button which should be 2-3 words but fewer than 25 characters and sentence case. */
  callToAction: PropTypes.node.isRequired,
  /** Subtitle for the pull quote that should be fewer than 30 characters and displayed in all caps. */
  eyebrow: PropTypes.string.isRequired,
  /** Should be either an `img` or `video`. */
  media: PropTypes.node,
  /** Text with the appearance as a h2 that should be fewer than 120 characters and sentence case. */
  quote: PropTypes.string.isRequired,
  /** Will normally be a name of the person quoted which should be fewer than 50 characters and sentence case. */
  secondary: PropTypes.string.isRequired,
};

export default PullQuote;
