import React, {
  AllHTMLAttributes,
  PropsWithChildren,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';

import useJoinedRef from '@mc/hooks/useJoinedRef';
import stylesheet from './GridLayout.less';

export type GridLayoutProps = PropsWithChildren<{
  fit?: boolean;
  gap?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  minColumnWidth?: string;
}> &
  AllHTMLAttributes<HTMLDivElement>;

/**
 * Given a container, `GridLayout` tries to fill it up with as many columns as
 * it can. No single column width can go under `minColumnWidth`. The number of
 * rows is fluid, dependent on the number of columns and the number of children.
 */
const GridLayout = React.forwardRef<HTMLDivElement, GridLayoutProps>(
  function GridLayout(
    {
      children,
      className,
      fit = true,
      gap = 2,
      minColumnWidth = '250px',
      style,
      ...props
    },
    forwardedRef,
  ) {
    const [isAboveMinColumnWidth, setIsAboveMinColumnWidth] = useState(false);
    const gridRef = useRef<HTMLDivElement>(null);
    const ref = useJoinedRef(forwardedRef, gridRef);

    useLayoutEffect(() => {
      if (typeof ResizeObserver !== 'undefined') {
        const node = gridRef.current;

        // Check if node is null
        if (!node) {
          return;
        }

        // Create a proxy element to measure and convert the `minColumnWidth` value
        // (which might be em, rem, etc) to `px`

        const test = document.createElement('div');
        test.style.width = minColumnWidth;

        node.appendChild(test);
        const minToPixels = test.offsetWidth;

        node.removeChild(test);

        const observer = new ResizeObserver((entries) => {
          // We're only ever observing one element anyway.
          const entry = entries[0];
          const { width } = entry.contentRect;
          setIsAboveMinColumnWidth(width > minToPixels);
        });

        observer.observe(node);
        return function unobserve() {
          observer.unobserve(node);
        };
      }
    }, [minColumnWidth]);

    return (
      <div
        ref={ref}
        {...props}
        className={cx(
          stylesheet.root,
          gap && stylesheet[`gap${gap}`],
          className,
        )}
        style={{
          ...style,
          gridTemplateColumns: isAboveMinColumnWidth
            ? `repeat(${
                fit ? 'auto-fit' : 'auto-fill'
              }, minmax(${minColumnWidth}, 1fr))`
            : undefined,
        }}
      >
        {children}
      </div>
    );
  },
);

export default GridLayout;
