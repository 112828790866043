import React, { createContext, useContext } from 'react';
import cx from 'classnames';
import stylesheet from './Table.css';

const TableHeadContext = createContext('');

export type TableElementProps = {
  children?: React.ReactNode;
};

export type TableProps = {
  caption: React.ReactNode;
  className?: string;
  fixedFirstColumn?: boolean;
  hideCaption?: boolean;
  isStriped?: boolean;
  stickyHeader?: boolean;
} & TableElementProps;

export type TableCellProps = {
  scope?: 'col' | 'row' | 'colgroup' | 'rowgroup';
  className?: string;
} & TableElementProps;

const Table = React.forwardRef<HTMLTableElement, TableProps>(function Table(
  {
    className,
    children,
    caption,
    hideCaption = false,
    isStriped = false,
    fixedFirstColumn = false,
    stickyHeader = false,
  },
  forwardedRef,
) {
  const classList = cx(stylesheet.root, className, {
    [stylesheet.striped]: isStriped,
    [stylesheet.fixedFirstColumn]: fixedFirstColumn,
    [stylesheet.stickyHeader]: stickyHeader,
  });
  return (
    <table ref={forwardedRef} className={classList}>
      <caption className={cx(hideCaption && 'wink-visually-hidden')}>
        {caption}
      </caption>
      {children}
    </table>
  );
});

const TableHead = ({ children }: TableElementProps) => {
  return (
    <TableHeadContext.Provider value="tHead">
      <thead>{children}</thead>
    </TableHeadContext.Provider>
  );
};

const TableBody = ({ children }: TableElementProps) => {
  return <tbody>{children}</tbody>;
};

const TableRow = ({ children }: TableElementProps) => {
  return <tr>{children}</tr>;
};

const TableCell = ({ children, scope, className }: TableCellProps) => {
  const tHead = useContext(TableHeadContext);
  /** Identify which element to use depending on the parent wrapper of <thead></thead> or <tbody></tbody>,
   * as well as whether or not the cell is a row header. */
  const Cell = tHead || scope ? 'th' : 'td';
  return (
    <Cell className={className} scope={scope}>
      {children}
    </Cell>
  );
};

export { Table as default, TableHead, TableBody, TableRow, TableCell };
