import React, { ReactElement } from 'react';

export type TabPanelsProps = {
  children: ReactElement[];
};

const TabPanels: React.FC<TabPanelsProps> = ({ children }) => {
  return (
    // This React.Fragment forces react-docgen to scrape this component
    // https://github.com/reactjs/react-docgen/issues/70
    <React.Fragment>
      {React.Children.map(children, (child, index) => {
        if (child === null) {
          return null;
        }
        return React.cloneElement(child, { index });
      })}
    </React.Fragment>
  );
};

export default TabPanels;
