import React, {
  LiHTMLAttributes,
  OlHTMLAttributes,
  HTMLAttributes,
  ReactNode,
} from 'react';
import cx from 'classnames';
import stylesheet from './List.css';

export type ListItemProps = {
  appearance?:
    | 'large'
    | 'large-secondary'
    | 'large-bold'
    | 'medium'
    | 'medium-secondary'
    | 'medium-bold'
    | 'small'
    | 'small-secondary'
    | 'small-bold';
} & LiHTMLAttributes<HTMLLIElement>;

const LIST_ITEM_NAME = '@mc/wink - ListItem';

export const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(
  function ListItem (
    { className, appearance = 'medium', ...props },
    forwardedRef,
  ) {
    return (
      <li
        className={cx(
          stylesheet.listItem,
          cx(stylesheet[appearance], className),
        )}
        ref={forwardedRef}
        {...props}
      />
    );
  },
);

ListItem.displayName = LIST_ITEM_NAME;

export type ListProps = {
  /** An appearance preset. */
  appearance?:
    | 'large'
    | 'large-secondary'
    | 'large-bold'
    | 'medium'
    | 'medium-secondary'
    | 'medium-bold'
    | 'small'
    | 'small-secondary'
    | 'small-bold';
  /** A class that gets applied to the ul/ol */
  /** Set to 2 to use a multi-column layout */
  columns?: 1 | 2;
  /** Either ul for an unordered list, or ol for an ordered list */
  type?: 'ul' | 'ol';
} & (HTMLAttributes<HTMLUListElement> | OlHTMLAttributes<HTMLOListElement>);

const List = React.forwardRef<HTMLElement, ListProps>(function List (
  {
    type = 'ul',
    className,
    children,
    appearance = 'medium',
    columns = 1,
    ...props
  },
  forwardedRef,
) {
  if (__DEV__) {
    React.Children.forEach(children, (child: ReactNode) => {
      if (
        React.isValidElement(child) &&
        typeof child.type !== 'string' &&
        'render' in child.type &&
        'displayName' in child.type &&
        child.type?.displayName === LIST_ITEM_NAME
      ) {
        return;
      } else {
        console.error(`List can only have children of type ListItem.`);
      }
    });
  }
  return (
    <>
      {React.createElement(type, {
        ref: forwardedRef,
        className: cx(
          stylesheet.list,
          `mcds-list-${type}-default`,
          stylesheet[appearance],
          columns > 1 && stylesheet.multiColumn,
          className,
        ),
        ...props,
        children,
      })}
    </>
  );
});

export default List;
