import React from 'react';
import PropTypes from 'prop-types';
import { WarningCircleIcon } from '@mc/wink-icons';
import { Text, ClusterLayout, TextButton } from '@mc/wink';
import useBreakpoints from '@mc/hooks/useBreakpoints';
import stylesheet from './SiteAlert.less';

const SiteMessage = React.forwardRef(function SiteMessage(
  { children, title, icon = <WarningCircleIcon />, action, onClose },
  forwardedRef,
) {
  const { isDesktop } = useBreakpoints();

  return (
    <ClusterLayout
      ref={forwardedRef}
      className={stylesheet.message}
      nowrap
      alignItems="flex-start"
    >
      {/* Icon */}
      {icon}

      <ClusterLayout
        justifyContent="space-between"
        nowrap={isDesktop}
        style={{ flex: 1 }}
      >
        {/* Copy */}
        <div
          className={stylesheet.description}
          style={{ flex: isDesktop && 1 }}
        >
          <Text appearance="small-bold">{title}</Text>
          <Text appearance="small-secondary">{children}</Text>
        </div>

        {/* Actions with Dismiss action */}
        <ClusterLayout nowrap={isDesktop}>
          {action}{' '}
          {onClose && <TextButton onClick={onClose}>Dismiss</TextButton>}
        </ClusterLayout>
      </ClusterLayout>
    </ClusterLayout>
  );
});

SiteMessage.propTypes = {
  /** Limit to two actions using TextButton. */
  action: PropTypes.node,
  /** Copy of SiteAlert. May take links. */
  children: PropTypes.node.isRequired,
  /**
   * Icon button will only take the icon component to render,
   * the component's name should match the one in wink-icon.
   * The whole syntax for rendering component needs to be included
   * (i.e. <MenuRightIcon />).
   *
   * The easiest way to verify the name is click the icon in
   * the library, check the component being called in the snippet.
   * https://designsystem.mailchimp.com/foundational/icons
   */
  icon: PropTypes.element,
  /** Function to dismiss FeedbackBlock */
  onClose: PropTypes.func,
  /** Title of SiteAlert. Will appear bold. */
  title: PropTypes.string.isRequired,
};

export default SiteMessage;
