import React from 'react';

export type TabContextType = {
    id?: number | string;
    currentIndex?: number | string;
    setCurrentIndex?: (currentIndex: number | string) => void;
    size?: number | string;
    focusIndex?: number | string;
    setFocusIndex?: (focusIndex: number | string) => void;
    showOutline?: boolean;
    setShowOutline?: (showOutline: boolean) => void;
    isManual?: boolean;
}

const TabContext = React.createContext<TabContextType | undefined>(undefined);

export default TabContext;
