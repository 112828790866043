import React, { HTMLAttributes, useContext } from 'react';
import TabContext from './TabContext';
import getTabId from './getTabId';
import stylesheet from './TabPanel.css';

export type TabPanelProps = {
  index?: number;
} & HTMLAttributes<HTMLElement>;

const TabPanel: React.FC<TabPanelProps> = ({ index, ...props }) => {
  const context = useContext(TabContext);

  // Check if context is undefined and throw an error if so
  if (context === undefined) {
    throw new Error('TabPanel must be used within a TabProvider');
  }
  const { currentIndex, id } = context;
  const tabId = getTabId(id, index);
  return (
    <div
      {...props}
      className={stylesheet.tabPanel}
      tabIndex={-1}
      hidden={currentIndex !== index}
      role='tabpanel'
      id={tabId && `${tabId}:panel`}
      aria-labelledby={tabId}
    />
  );
};

export default TabPanel;
