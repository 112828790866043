import React, { AllHTMLAttributes, PropsWithChildren, Ref } from 'react';
import cx from 'classnames';
import stylesheet from './ButtonOrLink.less';

export type ButtonOrLinkProps = PropsWithChildren<{
  htmlType?: 'submit' | 'reset' | 'button';
  ref?: Ref<HTMLAnchorElement | HTMLButtonElement>;
}> &
  AllHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>;

/**
 * A component for component library use only. It determines whether to render a
 * link or a button based on the `href` prop.
 */
const ButtonOrLink = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonOrLinkProps
>(function ButtonOrLink(
  { children, className, href, type = 'button', onClick, ...rest },
  forwardedRef,
) {
  const element = href ? 'a' : 'button';
  const props: ButtonOrLinkProps = {
    className: cx(stylesheet.root, className),
    onClick: onClick,
    ref: forwardedRef,
    href,
    ...rest,
  };

  if (element === 'button') {
    props.type = type;
  }

  if (element === 'a' && props.target === '_blank') {
    props.rel = 'noreferrer noopener';
  }

  // Disabled links are supposed to have no hrefs, so this is how we disable
  // href while still respecting the existing prop
  if (element === 'a' && props.disabled) {
    props.href = undefined;
    props.disabled = undefined;
    props.onClick = undefined;
  }

  return React.createElement(element, props, children);
});

export default ButtonOrLink;
