import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

// Translation for Form default values
export const TranslateForm = (count: number) => {
  return {
    optionalText: useDsTranslateMessage({
      id: 'mcds.form.optional_text',
      defaultMessage: 'Optional',
    }),
    requiredText: useDsTranslateMessage({
      id: 'mcds.form.required_text',
      defaultMessage: 'Required',
    }),
    feedbackErrorText: useDsTranslateMessage({
      id: 'mcds.form.feedback_error_text',
      defaultMessage: `There is ${count} error in your form.`,
      values: { count: count },
    }),
    feedbackErrorsText: useDsTranslateMessage({
      id: 'mcds.form.feedback_errors_text',
      defaultMessage: `There are ${count} errors in your form.`,
      values: { count: count },
    }),
  };
};
